import { Form, useFormikContext } from "formik";
import { useContext, useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import { ItemCoverSheetContext } from "../log-book-item-cover-sheet-dialog/cover-sheet-context/context";
import { ItemDialogFormContext } from "./item-context/context";
import Notify from "./../../notifications/Notify";
import { VALIDATE_ELN_ID } from "../../../gql/logBooksapi";
import { withApollo } from "react-apollo";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { generateID } from "@digitallab/grid-common-components";
import { Module, equipmentStatus, formStates, formTypes, tableReloadTime } from "../../../constants";
import { ConfirmDialog } from "../../../components/shared/ConfirmDialog";
import useDialog from "../../../utils/hooks/useDialog";
import ReasonDropdown from "../ReasonDropdown";
import { CircularProgress } from "@mui/material";
import { OwcButton, OwcTypography } from "@one/react";
import { useParams } from "react-router-dom";
import { ActionContainer, ContentContainer } from "./ItemFormDialogBodyStyle";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";
import { compose } from "redux";
import { loadInstrumentSuggestion, loadInstrumentSuggestions } from "./redux/actions";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { isEqual } from "lodash";
import omitDeep from "omit-deep-lodash";
import moment from "moment";
import SyncActionContent from "./SyncActionContent";

export const FullScreenCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-tap-highlight-color: transparent;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const ItemFormDialogBody = ({
  children,
  type,
  dataTestId,
  addNewDialogButtonLabel,
  client,
  createItemChangeFn,
  equipmentDetail,
  loadInstrumentSuggestions: loadSuggestions,
  loadInstrumentSuggestion: loadSuggestion
}) => {
  const { submitForm, isSubmitting, errors, setErrors, values, setValues, resetForm } = useFormikContext();
  const { handleOpenCoverSheet } = useContext(ItemCoverSheetContext);
  const { handleClose, isStandaloneInvId, equipmentActionObject, setFiles } = useContext(ItemDialogFormContext);

  const suggestion = useSelector((state) => state.runLogsForm.instrumentSuggestion);
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useMatomo();
  const { openDialog, ...dialogProps } = useDialog();
  const [selectedReason, setSelectedReason] = useState("");
  const [addComment, setAddComment] = useState("");
  const { inventoryId } = useParams();
  const {
    handleCloseWorkFlowModel,
    loadLogBookEquipment,
    formState,
    setRefreshCoverSheet,
    loadFormState,
    refreshTable
  } = useContext(CoverSheetMainPageContext);
  const { dispatchAction } = useContext(PaginationContext);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const updateReduxKeys = (updatedDetail) => {
    let tempDetail;
    updatedDetail = omitDeep(updatedDetail, "__typename", "id", "approverId", "approverUserName");
    if (type === formTypes?.RUN_LOG) {
      tempDetail = {
        ...updatedDetail,
        numberOfRuns: `${updatedDetail?.numberOfRuns}`,
        materialNumber: updatedDetail?.materialNumber || null
      };
    } else {
      tempDetail = {
        ...updatedDetail,
        updatedSoftwareVersion: {
          ...updatedDetail?.updatedSoftwareVersion,
          shouldBePublished: false
        }
      };
    }
    return tempDetail;
  };

  const updateFormikKeys = (updatedDetail) => {
    let tempDetail;
    updatedDetail = omitDeep(
      updatedDetail,
      "__typename",
      "editReason",
      "editComment",
      "runLogEntryId",
      "logSheetEntryId",
      "id",
      "approverId",
      "approverUserName"
    );
    if (type === formTypes?.RUN_LOG) {
      tempDetail = {
        ...updatedDetail,
        runStartDate: (updatedDetail.runStartDate =
          moment(updatedDetail?.runStartDate).format("YYYY-MM-DD") || updatedDetail.runStartDate),
        runEndDate: (updatedDetail.runEndDate =
          moment(updatedDetail?.runEndDate).format("YYYY-MM-DD") || updatedDetail.runEndDate),
        runIdentification: updatedDetail?.runIdentification || null,
        samplesProcessed: updatedDetail?.samplesProcessed || null,
        numberOfRuns: `${updatedDetail?.numberOfRuns}` || null,
        description: updatedDetail?.description || null,
        defectId: updatedDetail?.defectId || null,
        eLNid: updatedDetail?.eLNid || null,
        tipsUsed: updatedDetail?.tipsUsed || null,
        operatorId: updatedDetail?.operatorId || "",
        materialNumber: updatedDetail?.materialNumber || null
      };
    } else {
      tempDetail = {
        ...updatedDetail,
        actionDate: (updatedDetail.actionDate =
          moment(updatedDetail?.actionDate).format("YYYY-MM-DD") || updatedDetail.actionDate),
        ecrNumber: updatedDetail?.ecrNumber || null
      };
    }
    return tempDetail;
  };

  //effect setting save button state to disabled/enabled
  useEffect(() => {
    if (formState === formStates?.EDITABLE_FORM) {
      let tempEquipmentDetail = updateReduxKeys(equipmentDetail);
      let tempFormikValues = updateFormikKeys(values);
      setIsSubmitDisabled(() => isEqual(tempEquipmentDetail, tempFormikValues));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values)]);

  const eLNidValidationCheck = async (eLNid) => {
    try {
      const result = await client.query({
        query: VALIDATE_ELN_ID,
        fetchPolicy: "no-cache",
        variables: {
          eLNid
        }
      });
      return result?.data?.getElnIdValidation;
    } catch (err) {
      console.warn(err);
      Notify({
        type: "warning",
        icon: "alarm",
        appName: "",
        text: "Unable to verify eLN ID (either eLN or the interface is down)",
        closeButton: true
      });
    } finally {
    }
  };

  /**
   * function that checks if the log is beign added or edited
   * @returns { boolean } true for edited, false for added
   */
  const isEditable = formState === formStates?.EDITABLE_FORM;

  /**
  * function that checks if save button should be enabled https://retina.roche.com/cb/issue/3406273
  * @returns {boolean} true if selectedReason was chosen, false if not selected
  */
  const isConfirmSaveEnabled = () => Boolean(selectedReason)

  const handlerOnAddNew = async () => {
    // Track click on button
    if (type === formTypes?.ACTION_LOG) {
      trackEvent({ category: `Action ${type} added`, action: "click-event" });
    } else {
      trackEvent({ category: `${type} log added`, action: "click-event" });
    }
    handleSubmit(false);
  };

  const handlerOnEdit = async () => {
    // Track click on button
    if (type === formTypes?.ACTION_LOG) {
      trackEvent({ category: `Action ${type} Edited `, action: "click-event" });
    } else {
      trackEvent({ category: `Run Log Edited `, action: "click-event" });
    }
    handleSubmit(true);
  };

  const handleSubmit = async (closePopup = false) => {
    if (type === formTypes?.RUN_LOG && values?.eLNid) {
      setLoading(true);
      const eLNidValidation = await eLNidValidationCheck(values?.eLNid);
      setLoading(false);
      if (eLNidValidation?.statusCode === 400) {
        setErrors({
          eLNid: " "
        });
        Notify({
          type: "warning",
          icon: "alarm",
          appName: "",
          text: "Unable to verify eLN ID (either eLN or the interface is down)",
          closeButton: true
        });
      } else if (eLNidValidation?.statusCode === 403) {
        setErrors({
          eLNid: " "
        });
        Notify({
          type: "warning",
          icon: "alarm",
          appName: "",
          text: "Invalid eLN experiment ID",
          closeButton: true
        });
      } else if (eLNidValidation?.statusCode === 200) {
        setValues({
          ...values,
          editReason: selectedReason,
          editComment: addComment,
          eLNGUID: eLNidValidation?.elnAttributes[0]?.guid
        });
        const response = await createItemChangeFn(
          {
            ...values,
            editReason: selectedReason,
            editComment: addComment
          },
          {
            client,
            isNew: !isEditable,
            equipmentDetail
          }
        );
        setTimeout(() => {
          if (inventoryId && response?.data) {
            setRefreshCoverSheet((prevState) => !prevState);
          }
          dispatchAction({
            type: "clearAll"
          });
          refreshTable();
          resetForm();
        }, tableReloadTime);
        await submitForm();
        if (closePopup) {
          handleClose();
        } else {
          if (inventoryId || isStandaloneInvId) {
            setValues({
              ...values
            });
          } else {
            setValues({
              ...values,
              serialNumber: "",
              equipmentId: ""
            });
          }
        }
      }
    } else {
      setValues({
        ...values,
        editReason: selectedReason,
        editComment: addComment
      });
      const response = await createItemChangeFn(
        {
          ...values,
          editReason: selectedReason,
          editComment: addComment
        },
        {
          client,
          isNew: !isEditable,
          equipmentDetail
        }
      );
      setTimeout(() => {
        if (inventoryId && response?.data) {
          setRefreshCoverSheet((prevState) => !prevState);
        }
        dispatchAction({
          type: "clearAll"
        });
        refreshTable();
        resetForm();
      }, tableReloadTime);
      await submitForm();
      setFiles([]);

      if (closePopup) {
        handleClose();
      } else {
        if (inventoryId || isStandaloneInvId) {
          setValues({
            ...values
          });
        } else {
          setValues({
            ...values,
            serialNumber: "",
            equipmentId: ""
          });
        }
      }
    }
  };
  const updateReasons = () => {
    openDialog();
  };

  const openSyncDialog = () => {
    openDialog();
  };

  return (
    <>
      {loading && (
        <FullScreenCentered>
          <CircularProgress data-testid="app-loading" size={80} />
        </FullScreenCentered>
      )}
      {suggestion && (
        <OwcTypography
          variant="subtitle2"
          style={{
            color: "var(--one-color-interaction-default-brand-1)",
            cursor: "pointer"
          }}
          data-testid={`${dataTestId}-open-cover-sheet-button`}
          onClick={handleOpenCoverSheet}
          id={
            dataTestId === "run-log"
              ? generateID.buttonID(Module.BUTTON.run_log_popup.open_cover_sheet, "button")
              : generateID.buttonID(Module.BUTTON.action_log_popup.open_cover_sheet, "button")
          }
        >
          {LOGBOOK_LABEL.BUTTON.open_cover_sheet}
        </OwcTypography>
      )}
      <div slot="title">
        {!isEditable
          ? `Add ${type === formTypes?.RUN_LOG ? "Run" : "Action"} log`
          : `Edit ${type === formTypes?.RUN_LOG ? "Run" : "Action"} log`}
      </div>
      <ContentContainer slot="content">
        <Form>{children}</Form>
      </ContentContainer>

      <ActionContainer slot="actions">
        <OwcButton
          style={{ marginRight: "10px" }}
          data-testid={`${dataTestId}-creating-and-editing-dialog-actions-cancel-button`}
          disabled={isSubmitting}
          onClick={() => {
            handleClose();
            loadSuggestions([]);
            loadSuggestion(null);
            if (!inventoryId) {
              loadLogBookEquipment(null);
            }
            loadFormState(null);
            handleCloseWorkFlowModel();
          }}
          id={
            dataTestId === "run-log"
              ? generateID.buttonID(Module.BUTTON.run_log_popup.cancel, "button")
              : generateID.buttonID(Module.BUTTON.action_log_popup.cancel, "button")
          }
          variant="secondary"
        >
          {LOGBOOK_LABEL.BUTTON.cancel}
        </OwcButton>
        <div>
          {!isEditable ? (
            <div
              style={{
                display: "flex"
              }}
            >
              <OwcButton
                disabled={isSubmitting || Object.keys(errors).length > 0}
                variant="primary"
                onClick={async () => {
                  if (type === formTypes?.RUN_LOG) {
                    handlerOnAddNew(false);
                    loadSuggestions([]);
                    loadSuggestion(null);
                    if (!inventoryId) {
                      loadLogBookEquipment(null);
                    }
                    loadFormState(null);
                    handleCloseWorkFlowModel();
                  } else {
                    if (equipmentActionObject?.status === equipmentStatus?.pending?.key) {
                      handlerOnAddNew(false);
                      loadSuggestions([]);
                      loadSuggestion(null);
                      if (!inventoryId) {
                        loadLogBookEquipment(null);
                      }
                      loadFormState(null);
                      handleCloseWorkFlowModel();
                    } else {
                      openSyncDialog();
                    }
                  }
                }}
                data-testid={`${dataTestId}-creating-and-editing-dialog-actions-add-${type}-button`}
                id={
                  dataTestId === "run-log"
                    ? generateID.buttonID(Module.BUTTON.run_log_popup.add_new_run, "button")
                    : generateID.buttonID(Module.BUTTON.action_log_popup.add_additional_log, "button")
                }
              >
                {addNewDialogButtonLabel ?? `Add ${type === formTypes?.RUN_LOG ? "Run" : "Action"} log`}
              </OwcButton>
              <ConfirmDialog
                {...dialogProps}
                approveText="Proceed"
                approveColor="primary"
                approveVariant="contained"
                cancelText="Cancel"
                cancelVariant="outlined"
                cancelColor="primary"
                onApprove={async () => {
                  handlerOnAddNew(false);
                  loadSuggestions([]);
                  loadSuggestion(null);
                  if (!inventoryId) {
                    loadLogBookEquipment(null);
                  }
                  loadFormState(null);
                  handleCloseWorkFlowModel();
                }}
                title="Propagate to repository"
                isEnabled={true}
                content={<SyncActionContent />}
                disableBackdropClick={true}
              />
            </div>
          ) : (
            <>
              <OwcButton
                disabled={Object.keys(errors).length > 0 || isSubmitDisabled}
                onClick={() => {
                  updateReasons();
                }}
                data-testid={`${dataTestId}-creating-and-editing-dialog-actions-edit-button`}
                id={
                  dataTestId === "run-log"
                    ? generateID.buttonID(Module.BUTTON.run_log_popup.edit_run, "button")
                    : generateID.buttonID(Module.BUTTON.action_log_popup.edit_log, "button")
                }
              >
                Save
              </OwcButton>
              <ConfirmDialog
                {...dialogProps}
                approveText="OK"
                approveColor="primary"
                approveVariant="contained"
                cancelText="Cancel"
                cancelVariant="outlined"
                cancelColor="primary"
                isEnabled = {isConfirmSaveEnabled()}
                onApprove={async () => {
                  handlerOnEdit(true);
                  loadSuggestions([]);
                  loadSuggestion(null);
                  if (!inventoryId) {
                    loadLogBookEquipment(null);
                  }
                  loadFormState(null);
                  handleCloseWorkFlowModel();
                }}
                onCancel={() => {
                  setSelectedReason("");
                  setAddComment("");
                }}
                title="Reason for editing log entry"
                selectedReason={selectedReason}
                content={
                  <ReasonDropdown
                    selectedReason={selectedReason}
                    setSelectedReason={setSelectedReason}
                    addComment={addComment}
                    setAddComment={setAddComment}
                  />
                }
                disableBackdropClick={true}
              />
            </>
          )}
        </div>
      </ActionContainer>
    </>
  );
};

export default compose(
  connect(
    (store) => ({
      user: store.user
    }),
    {
      loadInstrumentSuggestions,
      loadInstrumentSuggestion
    }
  ),
  withApollo
)(ItemFormDialogBody);
