import React, { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import PageTabs from "./PageTabs";
import SearchFilterBar from "./SearchFilterBar";
import { useSelector } from "react-redux";
import moment from "moment";
import { OwcButton } from "@one/react";
import CustomFilterWrapper, { initialState } from "../custom-filter/CustomFilterWrapper";
import SearchTextWrapper from ".././log-book-item-fillter/suggester/search-context/SearchTextWrapper";
import PaginationWrapper from "../../../components/shared/pagination/PaginationWrapper";
import RunLogsPage from ".././log-book-pages/RunLogsPage";
import LogSheetPage from ".././log-book-pages/LogSheetPage";
import ChangeLogSheetPage from ".././log-book-pages/ChangeLogSheetPage";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";
import { entryType, equipmentStatus, formStates, formTypes } from "../../../constants";
import LogbookSelectedWrapper from "../log-book-table/logBooksSelectAll/LogBookSelectedWrapper";
import AllLogsPage from ".././log-book-pages/AllLogsPage";
import { toQueryParameter } from "../../../components/shared/useSearchParameters";
import { doNotPassNullString } from "../../../utils/helpers/text";

const MainContainer = styled.div``;

const LogBooksMainPage = () => {
  const history = useHistory();
  const { inventoryId } = useParams();
  const storeUser = useSelector((store) => store.user);
  const { tabValue, handleOpenWorkFlowModel, logBookEquipment, loadLogBookEquipment, logType, loadFormState } =
    useContext(CoverSheetMainPageContext);
  const canEdit = useSelector((store) => store.user.canEdit);
  const initialLog = {
    site: storeUser?.site,
    dateFrom: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    dateTo: moment(new Date()).format("YYYY-MM-DD"),
    inputsState: {
      ...initialState.inputsState,
      site: storeUser?.site,
      dateFrom: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
      dateTo: moment(new Date()).format("YYYY-MM-DD")
    }
  };
  const initialRunLog = initialLog;
  const initialActionLog = initialLog;

  useEffect(() => {
    if (!inventoryId) {
      loadLogBookEquipment(null);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MainContainer
        data-testid="log-book-main-page"
        style={{
          width: inventoryId === null ? "100%" : ""
        }}
      >
        <CustomFilterWrapper initialValues={logType !== formTypes?.ACTION_LOG ? initialRunLog : initialActionLog}>
          <PaginationWrapper>
            <SearchTextWrapper>
              {/* search bar  */}
              <SearchFilterBar value={logType} equipmentDetail={logBookEquipment} />
              <PageTabs value={logType} />
              {logType === formTypes?.RUN_LOG && (
                <LogbookSelectedWrapper equipmentDetail={logBookEquipment}>
                  <RunLogsPage equipmentDetail={logBookEquipment} />
                </LogbookSelectedWrapper>
              )}
              {logType === formTypes?.ACTION_LOG && (
                <LogbookSelectedWrapper equipmentDetail={logBookEquipment}>
                  <LogSheetPage equipmentDetail={logBookEquipment} />
                </LogbookSelectedWrapper>
              )}
              {logType === formTypes?.CHANGE_LOG && <ChangeLogSheetPage equipmentDetail={logBookEquipment} />}
              {logType === formTypes?.ALL_LOG && (
                <LogbookSelectedWrapper equipmentDetail={logBookEquipment}>
                  <AllLogsPage equipmentDetail={logBookEquipment} />
                </LogbookSelectedWrapper>
              )}
            </SearchTextWrapper>
          </PaginationWrapper>
        </CustomFilterWrapper>
      </MainContainer>
      {tabValue !== "change" &&
        tabValue !== "All logs" &&
        logBookEquipment?.status !== equipmentStatus?.deleted?.key &&
        canEdit && (
          <div
            style={{
              marginTop: "15px",
              marginRight: "30px",
              float: "right"
            }}
          >
            {inventoryId && logBookEquipment?.entryType === entryType?.cluster ? (
              <OwcButton
                variant="secondary"
                onClick={() => {
                  if (!inventoryId) {
                    loadLogBookEquipment(null);
                  }
                  loadFormState(null);
                  handleOpenWorkFlowModel({
                    isCluster: inventoryId && logBookEquipment?.entryType === entryType?.cluster,
                    form: inventoryId ? formStates?.PARTIAL_NEW_FORM : formStates?.NEW_FORM
                  });
                }}
                disabled={logBookEquipment?.clusterId}
              >
                {tabValue}
              </OwcButton>
            ) : (
              <OwcButton
                variant="secondary"
                onClick={() => {
                  const params =
                    (logBookEquipment &&
                      toQueryParameter("equipmentList", [
                        {
                          equipmentModel: doNotPassNullString(logBookEquipment.equipmentModel),
                          serialNumber: doNotPassNullString(logBookEquipment.serialNumber),
                          equipmentId: doNotPassNullString(logBookEquipment.equipmentId),
                          equipmentNickName: doNotPassNullString(logBookEquipment.equipmentNickName),
                          belongingToGroup: doNotPassNullString(logBookEquipment.belongingToGroup),
                          location: doNotPassNullString(logBookEquipment.location),
                          qualificationStatus: doNotPassNullString(logBookEquipment.qualificationStatus),
                          systemStatus: doNotPassNullString(logBookEquipment.systemStatus),
                          modelVersion: doNotPassNullString(logBookEquipment.modelVersion),
                          inventoryId: doNotPassNullString(logBookEquipment.inventoryId),
                          status: doNotPassNullString(logBookEquipment.status)
                        }
                      ])) ??
                    "";
                  history.push(logType === formTypes?.ACTION_LOG ? `/logs/action${params}` : `/logs/run${params}`);
                }}
              >
                Add {logType === formTypes?.RUN_LOG ? "Run" : "Action"} log(s)
              </OwcButton>
            )}
          </div>
        )}
    </>
  );
};

export default LogBooksMainPage;
